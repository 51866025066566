import React from 'react'
import { MenuItemVar } from '../features/menu/menuItemSlice'
import { FormOperationType } from '../app/const'
import { Divider, Typography } from '@mui/material'

export interface MenuItemVarListProps {
  menuItemVars: MenuItemVar[] | undefined
  operation?: FormOperationType
}

const MenuItemVarList = (props: MenuItemVarListProps) => {
  return (props.operation === FormOperationType.READ ? <>
    <Divider sx={{ my: 2 }} />
    {props.menuItemVars?.map((item: MenuItemVar, index: number) => <Typography variant="body2" color="text.secondary" gutterBottom key={index}>
      {item.quantity} {item.unit} • {item.price} {item.currency} • Available: {item.stock}
    </Typography>)}
  </> : <></>)
}

export default MenuItemVarList