import React, { } from 'react'
import AppPage from '../../components/AppPage'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SellerHome = () => {
  const navigate = useNavigate()
  return (<AppPage buttons={[
    <Button key="create-menu" onClick={() => navigate('/seller/menu/create/new')}>Create Menu</Button>,
    <Button key="menu" onClick={() => navigate('/seller/menus')}>Menu</Button>,
    <Button key="myorders" onClick={() => navigate('/seller/orders')}>Orders</Button>
  ]}>
  </AppPage>)
}

export default SellerHome