import React from 'react'
import { AppBar, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

const ResponsiveFooter = () => {
  const footerLinks = [{ label: 'Terms', url: '/terms' }, { label: 'Privacy', url: '/privacy' }, { label: 'Security', url: '/security' }, { label: 'Contact', url: '/contact' }, { label: 'Cookie', url: '/cookie' }, { label: 'Ads', url: '/adchoices' }, { label: 'Docs', url: '/docs' }, { label: 'App', url: '/app' }]
  const navigate = useNavigate()
  const scollIntoNavigate = (path: string) => {
    document.querySelector('#root')?.scrollIntoView({ behavior: 'smooth' })
    navigate(path)
  }
  return (
    <AppBar position="static" color="secondary" sx={{ minHeight: 60, display: 'flex', justifyContent: 'center', px: 1, bottom: 0 }}>
      <Grid container sx={{ px: 3 }}>
        <Grid item xs={12} md={3} onClick={() => scollIntoNavigate('/home')}><Typography sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} variant="caption">© 2024 Rathnas LLC.</Typography></Grid>
        {footerLinks.map((item, index) => <Grid key={index} item xs={3} md={1} onClick={() => scollIntoNavigate(item.url)}>
          <Typography sx={{ cursor: 'pointer' }} variant="caption">{item.label}</Typography>
        </Grid>)}
      </Grid>
    </AppBar>)
}

export default ResponsiveFooter