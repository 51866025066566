import React from 'react'
import AppPage from '../../components/AppPage'

const Pnf = () => {
  return (
    <AppPage>
    </AppPage>
  )
}

export default Pnf