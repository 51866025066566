import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import appReducer from '../features/app/appSlice'
import authReducer from '../features/auth/authSlice'
import orderReducer from '../features/order/orderSlice'
import menuReducer from '../features/menu/menuSlice'
import menuItemReducer from '../features/menu/menuItemSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    order: orderReducer,
    menu: menuReducer,
    menuItem: menuItemReducer
  },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
