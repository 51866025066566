import React from 'react'
import { Typography } from '@mui/material'
// import { OverridableStringUnion } from '@mui/types'
// import { Variant } from '@mui/material/styles/createTypography'
// import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { TypographyOwnProps } from '@mui/material/Typography/Typography'


const TypoMd = (props: TypographyOwnProps) => {
  return <Typography variant={props.variant} sx={{ display: { xs: 'none', md: 'block' }, ...props.sx }}>
    {props.children}
  </Typography>
}

export default TypoMd