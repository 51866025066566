import React, { SyntheticEvent, useEffect, useState } from 'react'
import AppPage from '../../components/AppPage'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Seller, getMenuForSeller, getSellerByPartialEmailAsync, selectSellerList, selectSellerMenu } from '../../features/order/orderSlice'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { debounce } from '@mui/material/utils'
import { Menu } from '../../features/menu/menuSlice'
import { Page } from '../../app/types'
import { MenuItem, getMenuItemsForMenuSlugAsync, selectMenuItems } from '../../features/menu/menuItemSlice'
import MenuItemList from '../../components/MenuItemList'

const OrderHome = () => {
  const dispatch = useAppDispatch()
  const [selectedSeller, setSelectedSeller] = useState<Seller>()
  const sellers: Seller[] = useAppSelector(selectSellerList)
  const menu: Page<Menu> = useAppSelector(selectSellerMenu)
  const menuItems: Page<MenuItem> | undefined = useAppSelector(selectMenuItems)

  const handleInputChange = debounce((event: SyntheticEvent<Element, Event>,
    value: string | null, reason: 'input' | 'reset' | 'clear') => {
    if (value && reason === 'input') {
      dispatch(getSellerByPartialEmailAsync(value))
    }
  }, 500)
  const handleChange = (event: React.SyntheticEvent, value: Seller | null) => {
    if (value) {
      setSelectedSeller(value)
      dispatch(getMenuForSeller(value))
    }
  }
  useEffect(() => {
    if (menu && menu.content.length > 0 && menu?.content[0].slug) {
      dispatch(getMenuItemsForMenuSlugAsync(menu?.content[0].slug))
    }
  }, [menu])

  return (<AppPage>
    <>
      <Autocomplete
        value={selectedSeller}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={sellers ?? []}
        renderInput={(params) => <TextField {...params} label="Seller" />}
        getOptionLabel={(option) => option.userEmail}
        isOptionEqualToValue={(option: Seller, value: Seller) => {
          return option.userEmail === value.userEmail
        }}
      />
      <MenuItemList menuItems={menuItems?.content} />
      {selectedSeller && selectedSeller.userEmail && menu?.content?.length === 0 && <Typography variant="body1" color="text.secondary">Apologies, this seller is not selling.</Typography>}
    </>
  </AppPage>)
}

export default OrderHome