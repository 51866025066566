import React, { useEffect } from 'react'
import Container from '@mui/material/Container'
import { Box } from '@mui/material'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import { useAppDispatch } from './app/hooks'
import { authInitAsync, authStateChangeAsync, remoteConfigAsync } from './features/auth/authSlice'
import { onAuthStateChanged } from 'firebase/auth'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/common/home'
import Login from './pages/common/login'
import Pnf from './pages/common/pnf'
import ProtectedRoute from './components/ProtectedRoute'
import Profile from './pages/common/profile'
import ResponsiveFooter from './components/ResponsiveFooter'
import AppInfo from './pages/common/appinfo'
import Docs from './pages/common/docs'
import AdChoices from './pages/common/adchoices'
import Cookie from './pages/common/cookie'
import Contact from './pages/common/contact'
import Security from './pages/common/security'
import Privacy from './pages/common/privacy'
import Terms from './pages/common/terms'
import About from './pages/common/about'
import SellerHome from './pages/seller/sellerHome'
import OrderHome from './pages/buyer/orderHome'
import AdminHome from './pages/admin/adminHome'
import OrderForm from './pages/buyer/orderForm'
import MenuForm from './pages/seller/menuForm'
import Navigate2 from './components/Navigate2'
import LoadingBackdrop from './components/LoadingBackdrop'
import { fireauth } from './app/fire'
import Menus from './pages/seller/menus'
import Test from './pages/seller/test'
import { setSubtitle, setTitle } from './features/app/appSlice'
import MenuItemForm from './pages/seller/menuItemForm'
import MenuItemList from './pages/seller/menuItemList'

let debounceTimer: NodeJS.Timeout

const titles = (pathname: string) => {
  if (pathname.startsWith('/seller')) {
    return { title: 'Seller', subtitle: '' }
  } else if (pathname.startsWith('/order')) {
    return { title: 'Order', subtitle: '' }
  } else if (pathname.startsWith('/profile')) {
    return { title: 'Profile', subtitle: '' }
  } else if (pathname.startsWith('/admin')) {
    return { title: 'Admin', subtitle: '' }
  } else if (pathname.startsWith('/app')) {
    return { title: 'App Info', subtitle: '' }
  } else if (pathname.startsWith('/about')) {
    return { title: 'About', subtitle: '' }
  } else if (pathname.startsWith('/docs')) {
    return { title: 'Docs', subtitle: '' }
  } else if (pathname.startsWith('/adchoices')) {
    return { title: 'AdChoices', subtitle: '' }
  } else if (pathname.startsWith('/cookie')) {
    return { title: 'Cookie', subtitle: '' }
  } else if (pathname.startsWith('/contact')) {
    return { title: 'Contact', subtitle: '' }
  } else if (pathname.startsWith('/security')) {
    return { title: 'Security', subtitle: '' }
  } else if (pathname.startsWith('/privacy')) {
    return { title: 'Privacy', subtitle: '' }
  } else if (pathname.startsWith('/terms')) {
    return { title: 'Terms', subtitle: '' }
  } else if (pathname.startsWith('/test')) {
    return { title: 'Test', subtitle: '' }
  } else if (pathname.startsWith('/login')) {
    return { title: 'Login', subtitle: '' }
  } else if (pathname.startsWith('/home')) {
    return { title: 'Home', subtitle: '' }
  } else {
    return { title: 'Oops! Page Not Found', subtitle: '' }
  }
}

function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const authStateChangeAsyncDebounced = () => { dispatch(authStateChangeAsync()) }
  useEffect(() => {
    onAuthStateChanged(fireauth, (usr) => {
      console.log(`onAuthStateChanged: ${usr?.email}`)
      if (usr) {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(authStateChangeAsyncDebounced, 500)
      } else {
        clearTimeout(debounceTimer)
      }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(authInitAsync())
    dispatch(remoteConfigAsync())
  }, [dispatch])

  useEffect(() => {
    dispatch(setTitle(titles(location.pathname).title))
    dispatch(setSubtitle(titles(location.pathname).subtitle))
  }, [location.pathname, dispatch])

  return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <ResponsiveAppBar />
    <Container maxWidth="xl" sx={{ minHeight: 'calc(100vh - 130px)' }}>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/home" />} /> */}
        <Route path="/" element={<Navigate2 to="/login" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app" element={<AppInfo />} />
        <Route path="/about" element={<About />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/adchoices" element={<AdChoices />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/security" element={<Security />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/test" element={<Test />} />
        <Route element={<ProtectedRoute redirectTo="/login" />}> {/* https://stackoverflow.com/a/69592617 */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/seller">
            <Route path="" element={<Navigate to="/seller/home" />} />
            <Route path="home" element={<SellerHome />} />
            <Route path="menu/:menuOperation/:menuSlug" >
              <Route path="" element={<MenuForm />} />
              <Route path="items" element={<MenuItemList />} />
              <Route path="item/:itemOperation/:menuItemSlug" element={<MenuItemForm />} />
            </Route>
            <Route path="menus" element={<Menus />} />
            <Route path="orders" element={<Pnf />} />
            <Route path="history" element={<Pnf />} />
            <Route path="payments" element={<Pnf />} />
            <Route path="cancel" element={<Pnf />} />
            <Route path="refund" element={<Pnf />} />
            <Route path="support" element={<Pnf />} />
            <Route path="rate" element={<Pnf />} />
            <Route path="message" element={<Pnf />} />
          </Route>
          <Route path="/order">
            <Route path="" element={<Navigate to="/order/home" />} />
            <Route path="home" element={<OrderHome />} />
            <Route path="create/:menuSlug" element={<OrderForm />} />
            <Route path="modify/:menuSlug/:orderSlug" element={<OrderForm />} />
            <Route path="history" element={<Pnf />} />
            <Route path="payment" element={<Pnf />} />
            <Route path="track" element={<Pnf />} />
            <Route path="cancel" element={<Pnf />} />
            <Route path="refund" element={<Pnf />} />
            <Route path="support" element={<Pnf />} />
            <Route path="feedback" element={<Pnf />} />
            <Route path="message" element={<Pnf />} />
          </Route>
        </Route>
        <Route path="*" element={<Pnf />} />
      </Routes>
      <LoadingBackdrop />
    </Container>
    <ResponsiveFooter />
  </Box>
  )
}

export default App
