import React, { useState } from 'react'
import { MenuItem, setMenuItem } from '../features/menu/menuItemSlice'
import { Box, Button, ButtonGroup, Card, CardContent, Grid, ListItemText, SxProps, Theme, Typography } from '@mui/material'
import { Add, Delete, Edit, Remove } from '@mui/icons-material'
import { FormOperationType } from '../app/const'
import { Masonry } from '@mui/lab'
import MenuItemVarList from './MenuItemVarList'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { useNavigate } from 'react-router-dom'
import { selectMenu } from '../features/menu/menuSlice'

export interface MenuItemListProps {
  menuItems: MenuItem[] | undefined
  operation?: FormOperationType
  sx?: SxProps<Theme>
}

const MenuItemList = (props: MenuItemListProps) => {
  const [quantity, setQuantity] = useState<number[]>(Array(props.menuItems?.length).fill(0))
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedMenu = useAppSelector(selectMenu)

  const handleIncrement = (index: number) => {
    const newQuantity = [...quantity]
    newQuantity[index]++
    setQuantity(newQuantity)
  }

  const handleDecrement = (index: number) => {
    const newQuantity = [...quantity]
    newQuantity[index] = Math.max(newQuantity[index] - 1, 0)
    setQuantity(newQuantity)
  }
  return (props.operation === FormOperationType.READ ? <Masonry columns={4} spacing={2} sx={{ my: 2 }}>
    {props.menuItems && props.menuItems.length ? props.menuItems.map((item: MenuItem, index: number) => <Card key={index} sx={{ minWidth: { xs: '100%', md: '50%' } }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h5" component="div">
            {item.name}
          </Typography>
          <ButtonGroup size='small' variant='outlined'>
            <Button onClick={() => {
              dispatch(setMenuItem(item))
              navigate(`/seller/menu/${FormOperationType.NESTED}/${selectedMenu?.slug}/item/${FormOperationType.UPDATE}/${item.slug}`)
            }}><Edit /></Button>
            <Button onClick={() => {
              dispatch(setMenuItem(item))
              navigate(`/seller/menu/${FormOperationType.NESTED}/${selectedMenu?.slug}/item/${FormOperationType.DELETE}/${item.slug}`)
            }}><Delete /></Button>
          </ButtonGroup>
        </Box>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {item.details}
        </Typography>
        <MenuItemVarList operation={props.operation} menuItemVars={item.menuItemVars} />
        <Typography variant="body2">
          {item.specialInstr}
        </Typography>
      </CardContent>
    </Card>
    ) : <>
      <Typography variant="body2">No menu items found</Typography>
    </>}
  </Masonry>
    : <Grid container spacing={2} sx={{ my: 2 }}>
      {props.menuItems?.map((item: MenuItem, index: number) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {item.name}
              </Typography>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {item.details}
              </Typography>
              {item.specialInstr && (
                <Typography variant="body2" gutterBottom>
                  Special Instructions: {item.specialInstr}
                </Typography>
              )}
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', alignItems: 'center', m: 1 }}>
              <Button variant="outlined" color="secondary" size="small"
                onClick={() => handleDecrement(index)} disabled={quantity[index] <= 0}>
                <Remove />
              </Button>
              <ListItemText primary={quantity[index]} sx={{ px: 3, flexGrow: 0 }} />
              <Button variant="outlined" color="secondary" size="small"
                onClick={() => handleIncrement(index)} disabled={false}>
                <Add />
              </Button>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>)
}

export default MenuItemList