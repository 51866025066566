import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppError, AsyncIO, Page } from '../../app/types'
import { RootState } from '../../app/store'
import axios from 'axios'
import { API_BASE, FormOperationType } from '../../app/const'
import { fireauth } from '../../app/fire'

export interface MenuItemVar {
  quantity: number
  unit: string
  currency: string
  price: number
  stock: number
}
export interface MenuItem {
  id?: number
  slug?: string
  name: string
  label: string
  tags: string
  details: string
  specialInstr: string
  enableSpecialInstr: boolean
  enableSellerAutoApprove: boolean
  sellerAutoApproveThreshold: number
  menuItemVars?: MenuItemVar[]
}
export interface MenuItemState {
  isInitialized: boolean
  isLoading: boolean
  isError: boolean
  error?: AppError
  selectedMenuItem?: MenuItem
  // savedMenuItem?: MenuItem
  menuItems?: Page<MenuItem>
}

export interface SaveMenuItemArgs {
  menuSlug: string
  menuItemSlug: string
  menuItem: MenuItem
  operation: string
  cb: (menuItem: MenuItem) => void
}

const initialState: MenuItemState = {} as MenuItemState

export const getMenuItemsForMenuSlugAsync = createAsyncThunk('menu/getMenuItemsForMenuSlugAsync', async (menuSlug: string, { getState }): Promise<AsyncIO<Page<MenuItem>>> => {
  const state = getState() as RootState
  if (state.auth.isAuthenticated) {
    const menuItems = (await axios.get(`${API_BASE}apps/noshood/menu/${menuSlug}/items`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
    return {
      response: menuItems,
      request: { slug: menuSlug }
    }
  }
  throw new Error('Not authenticated')
})

export const getMenuItemBySlugAsync = createAsyncThunk('menu/getMenuBySlugAsync', async (slug: string, { getState }): Promise<MenuItem> => {
  const state = getState() as RootState
  if (slug === 'new') {
    return initialState.selectedMenuItem as MenuItem
  }
  if (state.auth.isAuthenticated) {
    return (await axios.get(`${API_BASE}apps/noshood/menu/${slug}/items`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
  }
  throw new Error('Not authenticated')
})
export const saveMenuItemAsync = createAsyncThunk('menu/saveMenuItemAsync', async (args: SaveMenuItemArgs, { getState }): Promise<MenuItem> => {
  const state = getState() as RootState
  if (state.auth.isAuthenticated) {
    const url = `${API_BASE}apps/noshood/menu/${args.menuSlug}/item`
    const payload = args.menuItem
    const headers = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    }
    const rsp: MenuItem = (args.operation == FormOperationType.CREATE.toString() ? await axios.post(url, payload, headers)
      : await axios.put(url, payload, headers)).data
    args.cb(rsp)
    return rsp
  }
  throw new Error('Not authenticated')
})
const menuItemSlice = createSlice({
  name: 'menuItem',
  initialState,
  reducers: {
    setMenuItem: (state, action: PayloadAction<MenuItem>) => {
      state.isInitialized = true
      state.isLoading = false
      state.selectedMenuItem = action.payload
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload
    }
  }, extraReducers: (builder) => {
    builder.addCase(getMenuItemBySlugAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(getMenuItemBySlugAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      state.selectedMenuItem = action.payload
    }).addCase(getMenuItemBySlugAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    }).addCase(getMenuItemsForMenuSlugAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(getMenuItemsForMenuSlugAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      state.menuItems = action.payload.response
    }).addCase(getMenuItemsForMenuSlugAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    }).addCase(saveMenuItemAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(saveMenuItemAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      state.selectedMenuItem = action.payload
    }).addCase(saveMenuItemAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    })
  }
})

export default menuItemSlice.reducer
export const { setMenuItem, setIsError } = menuItemSlice.actions
export const selectMenuItem = (state: RootState) => state.menuItem.selectedMenuItem
//export const selectSavedMenuItem = (state: RootState) => state.menuItem.savedMenuItem
export const selectError = (state: RootState) => state.menuItem.error
export const selectIsError = (state: RootState) => state.menuItem.isError
export const selectIsMenuItemLoading = (state: RootState) => state.menuItem.isLoading
export const selectMenuItems = (state: RootState) => state.menuItem.menuItems