import { createSlice } from '@reduxjs/toolkit'
import { AppError } from '../../app/types'

export interface AppState {
  isInitialized: boolean
  isLoading: boolean
  isError: boolean
  error?: AppError
  title: string
  subtitle: string
}

const initialState: AppState = {} as AppState

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setSubtitle: (state, action) => {
      state.subtitle = action.payload
    },
    setIsInitialized: (state, action) => {
      state.isInitialized = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsError: (state, action) => {
      state.isError = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export default appSlice.reducer
export const { setTitle, setSubtitle, setIsInitialized, setIsLoading, setIsError, setError } = appSlice.actions
export const selectTitle = (state: { app: AppState }) => state.app.title
export const selectSubtitle = (state: { app: AppState }) => state.app.subtitle
export const selectIsInitialized = (state: { app: AppState }) => state.app.isInitialized
export const selectIsAppLoading = (state: { app: AppState }) => state.app.isLoading
export const selectIsError = (state: { app: AppState }) => state.app.isError
export const selectError = (state: { app: AppState }) => state.app.error
