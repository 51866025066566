import { createTheme } from '@mui/material'

// https://coolors.co/palette/e63946-f1faee-a8dadc-457b9d-1d3557
// https://coolors.co/palette/ffbe0b-fb5607-ff006e-8338ec-3a86ff
// https://coolors.co/palette/780000-c1121f-fdf0d5-003049-669bbc
// "error" | "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "text.disabled" | "text.hint" | "text.secondary" | "text.primary"
const defaultTheme = createTheme({
  palette: {
    primary: {
      // main: '#7676E6',
      // main: '#CDBDDD'
      // main: '#A53565'
      // main: '#35A565'
      // main: '#CC5C0C'
      main: '#E63646'
    },
    secondary: {
      // main: '#023242',
      // main: '#A2D2F2'
      // main: '#0CAC5C'
      main: '#A8D8D8'
    },
  },
  typography: {
    fontFamily: ['Plus Jakarta Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Plus Jakarta Sans'), local('Plus Jakarta Sans'), url('/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('ttf');
        }
      `,
    },
  }
})

export default defaultTheme