import React from 'react'
import AppPage from '../../components/AppPage'
import { useAppSelector } from '../../app/hooks'
import { selectAuthUser } from '../../features/auth/authSlice'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const Profile = () => {
  const user = useAppSelector(selectAuthUser)
  return (
    <AppPage>
      <Card sx={{ maxWidth: '350px', border: 0, textAlign: 'center' }} variant="outlined">
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          {user?.picture && <CardMedia component="img" image={user?.picture} sx={{ height: 100, width: 100, borderRadius: '50%' }} />}
        </CardContent>
        <CardContent>
          <Typography variant="caption" sx={{ color: grey[400] }}>{user?.userId}</Typography>
        </CardContent>
      </Card>
    </AppPage>
  )
}

export default Profile