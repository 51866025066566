import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { getMyMenusAsync, selectPublishedMenus, selectUnpublishedMenus, setMenu } from '../features/menu/menuSlice'
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FormOperationType } from '../app/const'
import { Add, Edit, ViewAgenda } from '@mui/icons-material'
import TypoMd from './TypoMd'

export interface MenuListForTabProps {
  live: boolean
}

const MenuListForTab = (props: MenuListForTabProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getMyMenusAsync(props.live))
  }, [props.live])
  const menus = useAppSelector(props.live ? selectPublishedMenus : selectUnpublishedMenus)
  return (<Grid container spacing={2} sx={{ my: 2 }}>
    {menus?.content && [...menus?.content || []]
      .filter(menu => menu !== undefined)
      .sort((a, b) => a.isDefault && !b.isDefault ? -1 : a.isDefault && !b.isDefault ? 1 : 0)
      .map((menu, index) => <Grid item xs={12} sm={6} md={4} key={index}>
        <Card sx={{ boxShadow: menu.isDefault ? 5 : 1 }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                {menu.nickname}
              </Typography>
              {menu.isDefault && <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                <i>Default</i>
              </Typography>}
              <Button onClick={() => {
                dispatch(setMenu(menu))
                navigate(`/seller/menu/${FormOperationType.UPDATE}/${menu.slug}`)
              }}><Edit /></Button>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Typography variant="caption" color="textSecondary" component="div">
              {menu.slug}
            </Typography>
            {menu.menuInstr && <Typography variant="caption" color="textSecondary" component="div">
              {menu.menuInstr}
            </Typography>}
            {menu.expiry && <Typography variant="caption" color="textSecondary">
              Expiry: {menu.expiry}
            </Typography>}
            {menu.orderCutoffTime && <Typography variant="caption" color="textSecondary">
              Order Cutoff: {menu.orderCutoffTime}
            </Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={() => {
                dispatch(setMenu(menu))
                navigate(`/seller/menu/${FormOperationType.READ}/${menu.slug}`)
              }}><ViewAgenda /> <TypoMd>View Menu Items</TypoMd> ({menu.menuItems?.length})</Button>
              <Button onClick={() => {
                dispatch(setMenu(menu))
                navigate(`/seller/menu/${FormOperationType.NESTED}/${menu.slug}/item/create/new`)
              }}><Add /> <TypoMd>Add Items</TypoMd></Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>)}
  </Grid>)
}

export default MenuListForTab