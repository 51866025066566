import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingBackdrop from './LoadingBackdrop'
import AppPage from './AppPage'
import { Typography } from '@mui/material'

export interface Navigate2Props {
  to: string
}

const Navigate2 = (props: Navigate2Props) => {
  const navigate = useNavigate()
  const isDev = process.env.NODE_ENV === 'development'
  useEffect(() => { setTimeout(() => { navigate(props.to) }, 100) }, [navigate])
  return <AppPage><>
    {isDev && <Typography variant='caption' sx={{ mb: 5, color: 'secondary' }}>Redirecting to {props.to}</Typography>}
    <LoadingBackdrop />
  </></AppPage>
}

export default Navigate2