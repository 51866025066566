import React from 'react'
import AppPage from '../../components/AppPage'
import { Typography } from '@mui/material'

const Docs = () => {
  return (<AppPage>
    <Typography variant="body1">Apologies, this masterpiece is still in the oven, sizzling with promise and awaiting its moment to dazzle your taste buds.</Typography>
  </AppPage>)
}

export default Docs