import React, { useEffect, useState } from 'react'
import { Alert, Box, ButtonGroup, LinearProgress, Snackbar, SxProps, Theme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { AppError } from '../app/types'

let counter = 0
export interface AppPageProps {
  alignItems?: 'baseline' | 'center' | 'end' | 'flex-end' | 'flex-start' | 'inherit' | 'initial' | 'normal' |
  'revert' | 'revert-layer' | 'self-end' | 'self-start' | 'start' | 'stretch' | 'unset'
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
  buttons?: JSX.Element[]
  sx?: SxProps<Theme>
  boxShadow?: number
  error?: AppError
  showError?: boolean
  onErrorClose?: () => void
}

const AppPage = (props: AppPageProps) => {
  const [errorProgress, setErrorProgress] = useState(0)
  useEffect(() => {
    let showErrorInterval: NodeJS.Timeout
    if (props.showError) {
      showErrorInterval = setInterval(() => {
        setErrorProgress((prev) => {
          if (prev < 100) {
            return prev + 5
          }
          return prev
        })
      }, 250)
    }
    return () => {
      setErrorProgress(0)
      clearInterval(showErrorInterval)
    }
  }, [props.showError])

  return (<Box id="app-page-box" sx={{
    display: 'flex', flexDirection: 'column', px: { xs: 0, md: 5 }, alignItems: props.alignItems ?? 'center', ...props.sx,
    boxShadow: props.boxShadow ?? 0, minHeight: 'calc(100vh - 180px)'
  }}>
    <Snackbar open={!!props.error && props.showError} autoHideDuration={6000} onClose={props.onErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Box>
        <Alert severity="error">{props.error?.message}</Alert>
        <LinearProgress value={errorProgress} variant='determinate' sx={{ height: 2 }} />
      </Box>
    </Snackbar>
    {props.buttons && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <ButtonGroup size="small" color="secondary">
        {props.buttons}
      </ButtonGroup>
    </Box>
    }
    <Box sx={{ py: 8, width: '100%', bgcolor: 'background.paper' }}>
      {typeof props.children === 'function' ? (props.children as () => JSX.Element)() : props.children}
    </Box>
    {process.env.NODE_ENV === 'development' && <Box id="app-page-counter" sx={{ color: 'divider', display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      <Typography variant="caption" sx={{ color: grey[600] }}>{counter++}</Typography>
    </Box>
    }
  </Box>)
}

export default AppPage