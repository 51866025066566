import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import AppPage from '../../components/AppPage'
import { Box, Button, Typography } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import { getMenuBySlugAsync, selectMenu } from '../../features/menu/menuSlice'

const MenuItemList = () => {
  const { menuSlug } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedMenu = useAppSelector(selectMenu)
  useEffect(() => {
    if (menuSlug && !selectedMenu) {
      dispatch(getMenuBySlugAsync(menuSlug))
      // dispatch(getMenuItemsForMenuSlugAsync(menuSlug))
    }
  }, [menuSlug, selectedMenu, dispatch])

  return (<AppPage buttons={[
    <Button key="create-menu" onClick={() => navigate('/seller/menu/create/new')}>Create Menu Items</Button>,
    <Button key="create-menu" onClick={() => menuSlug && dispatch(getMenuBySlugAsync(menuSlug))}> <Refresh /> </Button>
  ]}>
    {selectedMenu && <Box>
      <Typography variant="h6">Add items to <strong>{selectedMenu.nickname ?? selectedMenu.slug}</strong></Typography>
    </Box>}
  </AppPage>)
}

export default MenuItemList