import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppError, AsyncIO, Page } from '../../app/types'
import { RootState } from '../../app/store'
import axios from 'axios'
import { API_BASE, FormOperationType } from '../../app/const'
import { fireauth } from '../../app/fire'
import { MenuItem } from './menuItemSlice'

export interface Menu {
  id?: number
  slug?: string
  nickname: string
  menuInstr: string
  orderCutoffTime: string
  expiry: string
  live: boolean
  isDefault: boolean
  createdTime?: string
  menuItems?: MenuItem[]
}
export interface MenuState {
  isInitialized: boolean
  isLoading: boolean
  isError: boolean
  error?: AppError
  selectedMenu?: Menu
  savedMenu?: Menu
  unpublishedMenus?: Page<Menu>
  publishedMenus?: Page<Menu>
}

export interface SaveMenuArgs {
  menu: Menu
  operation: string
  cb: (menu: Menu) => void
}

const initialState: MenuState = {} as MenuState

export const getMyMenusAsync = createAsyncThunk('menu/getMyMenusAsync', async (live: boolean, { getState }): Promise<AsyncIO<Page<Menu>>> => {
  const state = getState() as RootState
  if (state.auth.isAuthenticated) {
    const menus = (await axios.get(`${API_BASE}apps/noshood/menu/menus?live=${live ? 'true' : 'false'}`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
    return {
      response: menus,
      request: { live }
    }
  }
  throw new Error('Not authenticated')
})

export const getMenuBySlugAsync = createAsyncThunk('menu/getMenuBySlugAsync', async (slug: string, { getState }): Promise<Menu> => {
  const state = getState() as RootState
  if (slug === 'new') {
    return initialState.selectedMenu as Menu
  }
  if (state.auth.isAuthenticated) {
    if (slug === state.menu.selectedMenu?.slug) {
      return state.menu.selectedMenu
    } else if (slug === 'new') {
      return initialState.selectedMenu as Menu
    }
    return (await axios.get(`${API_BASE}apps/noshood/menu/${slug}`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
  }
  throw new Error('Not authenticated')
})
export const saveMenuAsync = createAsyncThunk('menu/saveMenuAsync', async (args: SaveMenuArgs, { getState }): Promise<Menu> => {
  const state = getState() as RootState
  if (state.auth.isAuthenticated) {
    const url = `${API_BASE}apps/noshood/menu`
    const payload = args.menu
    const headers = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    }
    const rsp: Menu = (args.operation == FormOperationType.CREATE.toString() ? await axios.post(url, payload, headers)
      : await axios.put(url, payload, headers)).data
    if (args.cb) args.cb(rsp)
    return rsp
  }
  throw new Error('Not authenticated')
})
const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<Menu>) => {
      state.isInitialized = true
      state.isLoading = false
      state.selectedMenu = action.payload
    },
    clearSavedMenu: (state) => {
      state.savedMenu = undefined
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload
    }
  }, extraReducers: (builder) => {
    builder.addCase(getMenuBySlugAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(getMenuBySlugAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      state.selectedMenu = action.payload
    }).addCase(getMenuBySlugAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    }).addCase(getMyMenusAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(getMyMenusAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      if (action.payload.request.live) {
        state.publishedMenus = action.payload.response
      } else {
        state.unpublishedMenus = action.payload.response
      }
    }).addCase(getMyMenusAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    }).addCase(saveMenuAsync.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.error = undefined
    }).addCase(saveMenuAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.error = undefined
      state.selectedMenu = action.payload
      state.savedMenu = action.payload
    }).addCase(saveMenuAsync.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error
    })
  }
})

export default menuSlice.reducer
export const { setMenu, setIsError, clearSavedMenu } = menuSlice.actions
export const selectMenu = (state: RootState) => state.menu.selectedMenu
// export const selectSavedMenu = (state: RootState) => state.menu.savedMenu
export const selectError = (state: RootState) => state.menu.error
export const selectIsError = (state: RootState) => state.menu.isError
export const selectIsMenuLoading = (state: RootState) => state.menu.isLoading
export const selectPublishedMenus = (state: RootState) => state.menu.publishedMenus
export const selectUnpublishedMenus = (state: RootState) => state.menu.unpublishedMenus