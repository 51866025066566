import React from 'react'

import { Backdrop, CircularProgress } from '@mui/material'
import { useAppSelector } from '../app/hooks'
import { selectLoadingBackdrop } from '../features/auth/authSlice'

const LoadingBackdrop = () => {
  const loading = useAppSelector(selectLoadingBackdrop)
  return (<Backdrop open={loading}>
    <CircularProgress color="inherit" />
  </Backdrop>)
}

export default LoadingBackdrop