import { initializeApp } from 'firebase/app'
import { IS_PROD } from './const'
import { getAuth } from 'firebase/auth'
import { getRemoteConfig } from 'firebase/remote-config'

export const fireapp = initializeApp(IS_PROD ? {
  apiKey: 'AIzaSyBVLfZw2IWh8TlJsNCWO9V6E6_vcKCEI1M',
  authDomain: 'entwine-prod.firebaseapp.com',
  projectId: 'entwine-prod',
  storageBucket: 'entwine-prod.appspot.com',
  messagingSenderId: '35323654963',
  appId: '1:35323654963:web:a7c2f05d854824f09f0446',
  measurementId: 'G-9L5734M9T6'
} : {
  apiKey: 'AIzaSyCuT9geUuQhteaw2XQvlrrDnRiy2vbEsN4',
  authDomain: 'entwine-test.firebaseapp.com',
  projectId: 'entwine-test',
  storageBucket: 'entwine-test.appspot.com',
  messagingSenderId: '213357079154',
  appId: '1:213357079154:web:66188390128a1509c3b0eb',
  measurementId: 'G-GP9W99CKGX'
})

export const fireauth = getAuth(fireapp)
export const remoteConfig = getRemoteConfig(fireapp)