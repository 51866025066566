import { AppBar, Avatar, Box, Button, IconButton, LinearProgress, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectAuthUser, selectIsAuth, selectIsAuthLoading, signOutAsync } from '../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { Home, Close } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { grey } from '@mui/material/colors'
import { selectIsAppLoading, selectSubtitle, selectTitle } from '../features/app/appSlice'
import { selectIsMenuLoading } from '../features/menu/menuSlice'
import { selectIsOrderLoading } from '../features/order/orderSlice'

interface MenuItem {
  label: string
  url: string
  desc?: string
  disabled?: boolean
}
const defaultMenuItems: MenuItem[] = [
  { url: '/order', label: 'Buy' },
]

const ResponsiveAppBar = () => {
  const isAuth = useAppSelector(selectIsAuth)
  const user = useAppSelector(selectAuthUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const title = useAppSelector(selectTitle)
  const subtitle = useAppSelector(selectSubtitle)
  const [loading, setLoading] = useState(true)
  const showAppLoading = useAppSelector(selectIsAppLoading)
  const showMenuLoading = useAppSelector(selectIsMenuLoading)
  const showAuthLoading = useAppSelector(selectIsAuthLoading)
  const showOrderLoading = useAppSelector(selectIsOrderLoading)
  useEffect(() => {
    if (user) {
      const sellerItems = [{ url: '/seller/menus', label: 'Menu' }, { url: '/seller/orders', label: 'Orders' }]
      const adminItems = [{ url: '/admin', label: 'Admin' }]
      const isSeller = user?.roles?.filter(e => e === 'ROLE_SELLER').length > 0
      const isAdmin = user?.roles?.filter(e => e === 'ROLE_ADMIN').length > 0
      setMenuItems([...defaultMenuItems, ...isSeller || isAdmin ? sellerItems : [], ...isAdmin ? adminItems : []])
    }
  }, [user])
  useEffect(() => {
    setLoading(showAppLoading || showMenuLoading || showAuthLoading || showOrderLoading)
  }, [showAppLoading, showMenuLoading, showAuthLoading, showOrderLoading])

  return (<AppBar position="static" sx={{ minHeight: 55, display: 'flex', justifyContent: 'center', boxShadow: 0 }}>
    <LinearProgress sx={{ height: 2, width: '100%', display: loading ? 'block' : 'none' }} color="info" variant='indeterminate' />
    <Toolbar sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        {menuItems.length > 0 ? <>
          <IconButton aria-controls="menu-appbar" size="large" aria-label="account of current user" aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget)} color="inherit" >
            {anchorElNav ? <Close /> : <MenuIcon />}
          </IconButton>
          <Menu id="menu-appbar" anchorEl={anchorElNav} keepMounted
            open={Boolean(anchorElNav)} onClose={() => setAnchorElNav(null)}
            sx={{ display: { xs: 'block' }, }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }} >
            {menuItems?.map((item, index) => <MenuItem key={index} sx={{
              borderBottom: (menuItems.length === index + 1 ? 0 : 1),
              borderColor: 'divider'
            }} onClick={() => {
              setAnchorElNav(null)
              navigate(item.url)
            }}><Box>
                <Typography textAlign="center">
                  {item.label}
                </Typography>
              </Box>
            </MenuItem>)}
          </Menu>
        </> : <></>}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
          {title && <Typography variant="body1">{title}</Typography>}
          {subtitle && <Typography variant="caption" sx={{ color: 'paper' }}>{subtitle}</Typography>}
        </Box>
      </Box>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none' } }}>
        <Button onClick={() => {
          setAnchorElNav(null)
          navigate('/home')
        }} sx={{ my: 2, color: '#fff', fontWeight: 'bold', display: 'block' }}>
          <Home />
        </Button>
      </Typography>
      {isAuth ? <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <Button aria-controls="menu-authbar" aria-haspopup="true" sx={{ p: 0, color: '#fff' }} onClick={
            (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget)} >
            {user?.displayName?.substring(0, user?.displayName.indexOf(' '))}
            {anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Tooltip>
        <Menu sx={{ mt: 4 }} id="menu-authbar" anchorEl={anchorElUser} keepMounted
          open={Boolean(anchorElUser)} onClose={() => setAnchorElUser(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }} onClick={() => {
            navigate('/profile')
            setAnchorElUser(null)
          }}>
            <Box>
              <Typography>Profile</Typography>
              <Typography variant='caption' color={grey[400]}>{user?.userEmail}</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => {
            dispatch(signOutAsync())
            setAnchorElUser(null)
            navigate('/home')
          }}>
            <Typography textAlign="center">Signout</Typography>
          </MenuItem>
        </Menu>
      </Box> : <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Signin">
          <IconButton onClick={() => navigate('/login')} sx={{ p: 0 }}>
            <Avatar alt={user?.displayName ? user?.displayName as string : user?.userEmail as string} src={user?.picture as string} />
          </IconButton>
        </Tooltip>
      </Box>}
    </Toolbar>
  </AppBar>)
}

export default ResponsiveAppBar