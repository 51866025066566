import React from 'react'
import {
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom'

import { Box } from '@mui/material'
import { useAppSelector } from '../app/hooks'
import { selectIsAuth } from '../features/auth/authSlice'

export interface ProtectedRouteProps {
  redirectTo: string
}
const ProtectedRoute = ({ redirectTo }: ProtectedRouteProps) => {
  const location = useLocation()
  const isAuth = useAppSelector(selectIsAuth)
  return isAuth ? <Box mt={1}> <Outlet /> </Box> : <Navigate to={redirectTo} state={{ from: location }} />
}

export default ProtectedRoute