import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppError, Page } from '../../app/types'
import { RootState } from '../../app/store'
import axios from 'axios'
import { API_BASE } from '../../app/const'
import { fireauth } from '../../app/fire'
import { Menu } from '../menu/menuSlice'

export interface Seller {
  id: number
  userId: string
  userEmail: string
}

export interface OrderSliceState {
  isLoading: boolean
  isError: boolean
  error: AppError
  sellerList: Seller[]
  sellerMenu: Page<Menu>
}

const initialState = {} as OrderSliceState

export const getMenuForSeller = createAsyncThunk('menu/getMenuForSeller', async (seller: Seller, { getState }): Promise<Page<Menu>> => {
  const state = getState() as RootState
  if (!seller) {
    return {} as Page<Menu>
  }
  if (state.auth.isAuthenticated) {
    return (await axios.post(`${API_BASE}apps/noshood/menu/default`, seller, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
  }
  throw new Error('Not authenticated')
})

export const getSellerByPartialEmailAsync = createAsyncThunk('menu/getSellerByPartialEmailAsync', async (partialEmail: string, { getState }): Promise<Seller[]> => {
  const state = getState() as RootState
  if (partialEmail.length < 2) {
    return []
  }
  if (state.auth.isAuthenticated) {
    return (await axios.post(`${API_BASE}apps/noshood/order/search/bySellerEmail`, { partialEmail }, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${await fireauth.currentUser?.getIdToken()}`
      }
    })).data
  }
  throw new Error('Not authenticated')
})

const orderSlice = createSlice({
  name: 'sliceName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSellerByPartialEmailAsync.pending, (state) => {
      state.isLoading = true
      state.error = initialState.error
    }).addCase(getSellerByPartialEmailAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.sellerList = action.payload
    }).addCase(getSellerByPartialEmailAsync.rejected, (state, action) => {
      state.isLoading = false
      state.error = { message: action.error.message }
    }).addCase(getMenuForSeller.pending, (state) => {
      state.isLoading = true
      state.error = initialState.error
    }).addCase(getMenuForSeller.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = initialState.error
      state.sellerMenu = action.payload
    }).addCase(getMenuForSeller.rejected, (state, action) => {
      state.isLoading = false
      state.error = { message: action.error.message }
    })
  },
})

export default orderSlice.reducer
export const selectSellerList = (state: RootState) => state.order.sellerList
export const selectSellerMenu = (state: RootState) => state.order.sellerMenu
export const selectIsOrderLoading = (state: RootState) => state.order.isLoading