import React, { } from 'react'
import AppPage from '../../components/AppPage'
import { useParams } from 'react-router-dom'

const OrderForm = () => {
  const { menuSlug } = useParams()
  return (<AppPage>
    {menuSlug}
  </AppPage>
  )
}

export default OrderForm