import React, { useEffect } from 'react'
import AppPage from '../../components/AppPage'
import { useNavigate, useParams } from 'react-router-dom'
import { FormOperationType } from '../../app/const'
import { Box, Button, ButtonGroup, Card, CardContent, FormControl, FormHelperText, Input, InputLabel, Typography } from '@mui/material'
import { Close, Edit, Save } from '@mui/icons-material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { red } from '@mui/material/colors'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Menu, clearSavedMenu, getMenuBySlugAsync, saveMenuAsync, selectError, selectIsError, selectMenu, setIsError } from '../../features/menu/menuSlice'
import MenuItemList from '../../components/MenuItemList'

export type MenuFormType = {
  nickname: string
  menuInstr: string
  orderCutoffTime: string
  expiry: string
  live: boolean
  isDefault: boolean
};

const MenuForm = () => {
  const { menuOperation, menuSlug } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<MenuFormType>()
  const menuForm: Menu | undefined = useAppSelector(selectMenu)
  const error = useAppSelector(selectError)
  const showError = useAppSelector(selectIsError)
  // const menuItems: Page<MenuItem> | undefined = useAppSelector(selectMenuItems)
  // const savedMenu = useAppSelector(selectSavedMenu)

  useEffect(() => {
    if (menuOperation === FormOperationType.CREATE || menuOperation === FormOperationType.UPDATE ||
      menuOperation === FormOperationType.READ || menuOperation === FormOperationType.DELETE) {
      dispatch(getMenuBySlugAsync(menuSlug ?? 'new'))
    }
    if (menuOperation === FormOperationType.READ) {
      if (menuSlug) {
        //dispatch(getMenuItemsForMenuSlugAsync(menuSlug))
        dispatch(clearSavedMenu())
      }
    }
  }, [menuOperation, menuSlug])

  useEffect(() => {
    setValue('nickname', menuForm?.nickname ?? `Menu${dayjs().format('YYYYMMDD')}`)
    setValue('menuInstr', menuForm?.menuInstr ?? '')
    setValue('orderCutoffTime', menuForm?.orderCutoffTime ?? '')
    setValue('expiry', menuForm?.expiry ?? '')
    setValue('live', menuForm?.live ?? false)
    setValue('isDefault', menuForm?.isDefault ?? false)
  }, [menuForm, setValue])

  const onSubmit: SubmitHandler<MenuFormType> = (data) => {
    menuOperation && dispatch(saveMenuAsync({
      operation: menuOperation, menu: {
        nickname: data.nickname,
        menuInstr: data.menuInstr,
        orderCutoffTime: data.orderCutoffTime,
        expiry: data.expiry,
        live: data.live,
        slug: menuSlug,
        isDefault: data.isDefault
      }, cb: (rsp: Menu) => {
        if (menuOperation === FormOperationType.UPDATE) {
          navigate('/seller/menus')
        } else {
          navigate(`/seller/menu/${FormOperationType.NESTED}/${rsp.slug}/item/${FormOperationType.CREATE}/new`)
        }
      }
    }))
  }

  return (<AppPage error={error} showError={showError} onErrorClose={() => dispatch(setIsError(false))}>
    <Box>
      {/* {savedMenu && <Navigate to={`/seller/menu/${FormOperationType.NESTED}/${savedMenu.slug}/items`} />} */}
      {menuOperation === FormOperationType.READ ? <>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h5">
                {menuForm?.nickname}
              </Typography>
              <ButtonGroup size='small'>
                <Button color="secondary" variant='outlined' onClick={() => { navigate(`/seller/menu/${FormOperationType.UPDATE}/${menuSlug}`) }}><Edit /></Button>
                <Button color="secondary" variant='outlined' onClick={() => { navigate('/seller/menus') }}><Close /></Button>
              </ButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography color="textSecondary" variant='caption' gutterBottom>
                {menuForm?.createdTime?.substring(0, 10)}
              </Typography>
              <Typography color="textSecondary" variant='caption' gutterBottom>
                {menuForm?.live ? 'Published' : 'Draft'}
              </Typography>
              <Typography color="textSecondary" variant='caption' gutterBottom>
                {menuForm?.isDefault ? 'Default ✓' : ''}
              </Typography>
            </Box>
            {menuForm?.menuInstr && <Typography color="textSecondary">
              Instructions: {menuForm?.menuInstr}
            </Typography>}
          </CardContent>
        </Card>
        <MenuItemList menuItems={menuForm?.menuItems} operation={menuOperation} />
      </>
        : <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="nicknameLabel">Nickname</InputLabel>
            <Input {...register('nickname', { required: true })} id="nicknameInput" aria-describedby="nicknameHelper" sx={{ pl: 2 }} />
            {errors.nickname ? <FormHelperText id="nicknameHelper" sx={{ color: red[500] }}>Nickname for the menu. Required</FormHelperText>
              : <FormHelperText id="nicknameHelper">Nickname for the menu.</FormHelperText>}
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="menuInstrLabel">Instructions</InputLabel>
            <Input {...register('menuInstr')} id="menuInstrInput" aria-describedby="menuInstrHelper" sx={{ pl: 2 }} />
            <FormHelperText id="menuInstrHelper">Special instructions for buyers, optional.</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="orderCutoffTimeLabel">Order Cutoff Time</InputLabel>
            <Input {...register('orderCutoffTime')} id="orderCutoffTimeInput" aria-describedby="orderCutoffTimeHelper" sx={{ pl: 2 }} />
            <FormHelperText id="orderCutoffTimeHelper">Accept orders until this date, optional</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="expiryLabel">Expiry</InputLabel>
            <Input {...register('expiry')} id="expiryInput" aria-describedby="expiryHelper" sx={{ pl: 2 }} />
            <FormHelperText id="expiryHelper">Menu will not be live after this date, optional</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <label>
              <input type="checkbox" {...register('live')} /> Publish
            </label>
            <FormHelperText id="isLiveHelper">When you are ready to serve, publish to go live.</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <label>
              <input type="checkbox" {...register('isDefault')} /> Default
            </label>
            <FormHelperText id="isLiveHelper">Make this a default menu.</FormHelperText>
          </FormControl>
          <ButtonGroup size="small" color="secondary">
            {menuOperation === FormOperationType.UPDATE ? <Button type="submit" color="primary" variant='contained' onClick={() => { console.log('saved') }}><Save /> Save</Button>
              : <Button type="submit" color="primary" variant='contained' onClick={() => { console.log('saved') }}><Save /> Save & add items</Button>}
            <Button color="secondary" variant='outlined' onClick={() => { navigate(-1) }}><Close /> Cancel</Button>
          </ButtonGroup>
        </form>}
    </Box>
  </AppPage>
  )
}

export default MenuForm