import React, { useState } from 'react'
import AppPage from '../../components/AppPage'
import { useAppDispatch } from '../../app/hooks'
import { getMyMenusAsync } from '../../features/menu/menuSlice'
import { Button, Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Add, Refresh } from '@mui/icons-material'
import MenuListForTab from '../../components/MenuListForTab'
import TypoMd from '../../components/TypoMd'

const Menus = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState(0)

  return (<AppPage buttons={[
    <Button key="create-menu" variant='contained' onClick={() => navigate('/seller/menu/create/new')}><Add /> <TypoMd>Create Menu</TypoMd></Button>,
    <Button key="refresh" variant='contained' onClick={() => dispatch(getMyMenusAsync(activeTab === 0))}> <Refresh /> <TypoMd>Reload</TypoMd> </Button>
  ]}>
    <Tabs value={activeTab} aria-label="Menu tabs"
      onChange={(event: React.SyntheticEvent, newVal: number) => setActiveTab(newVal)}>
      <Tab label="Published"></Tab>
      <Tab label="Drafts"></Tab>
    </Tabs>
    <MenuListForTab live={activeTab === 0} />
  </AppPage >)
}

export default Menus