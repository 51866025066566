import React from 'react'
import AppPage from '../../components/AppPage'
import { Button, Typography } from '@mui/material'

const AppInfo = () => {
  return (<AppPage>
    <Typography variant="body1">{process.env.REACT_APP_GH_BUILD_DATE}</Typography>
    <Typography variant="caption">{process.env.REACT_APP_GH_BUILD_VERSION}</Typography>
    <Typography variant="caption">
      {process.env.REACT_APP_GH_BUILD_RUN_ID}.{process.env.REACT_APP_GH_BUILD_RUN_NUMBER}.{process.env.REACT_APP_GH_BUILD_RUN_ATTEMPT}
    </Typography>
    <Button variant="text" color="secondary" href={process.env.REACT_APP_GH_BUILD_COMMIT_URL}>
      {process.env.REACT_APP_GH_BUILD_COMMIT_USER}
    </Button>
  </AppPage>)
}

export default AppInfo