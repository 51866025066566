import React, { useEffect, useState } from 'react'
import AppPage from '../../components/AppPage'
import { useNavigate, useParams } from 'react-router-dom'
import { FormOperationType } from '../../app/const'
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Collapse, FormControl, FormControlLabel, FormHelperText, Input, InputLabel, Switch, Typography } from '@mui/material'
import { Close, Edit, Save } from '@mui/icons-material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { red } from '@mui/material/colors'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { MenuItem, getMenuItemsForMenuSlugAsync, saveMenuItemAsync, selectError, selectIsError, selectMenuItem, selectMenuItems, setIsError } from '../../features/menu/menuItemSlice'
import MenuItemList from '../../components/MenuItemList'
import { Page } from '../../app/types'
import { getMenuBySlugAsync, selectMenu } from '../../features/menu/menuSlice'

export type MenuItemFormType = {
  name: string
  specialInstr: string
  details: string
  label: string
  tags: string
  enableSpecialInstr: boolean
  enableSellerAutoApprove: boolean
  sellerAutoApproveThreshold: number
};

const MenuItemForm = () => {
  const { itemOperation, menuSlug, menuItemSlug } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<MenuItemFormType>()
  const menuItemForm: MenuItem | undefined = useAppSelector(selectMenuItem)
  const error = useAppSelector(selectError)
  const showError = useAppSelector(selectIsError)
  const menuItems: Page<MenuItem> | undefined = useAppSelector(selectMenuItems)
  const selectedMenu = useAppSelector(selectMenu)
  const [advanced, setAdvanced] = useState(false)

  useEffect(() => {
    if (menuSlug && !selectedMenu) {
      dispatch(getMenuBySlugAsync(menuSlug))
    }
    if (menuSlug && (!menuItems || menuItems?.content?.length === 0)) {
      dispatch(getMenuItemsForMenuSlugAsync(menuSlug))
    }
  }, [menuSlug])

  useEffect(() => {
    setValue('name', menuItemForm?.name ?? `MenuItem${dayjs().format('YYYYMMDD')}`)
    setValue('specialInstr', menuItemForm?.specialInstr ?? '')
    setValue('details', menuItemForm?.details ?? '')
    setValue('label', menuItemForm?.label ?? '')
    setValue('tags', menuItemForm?.tags ?? '')
    setValue('enableSpecialInstr', menuItemForm?.enableSpecialInstr ?? false)
    setValue('enableSellerAutoApprove', menuItemForm?.enableSellerAutoApprove ?? true)
    setValue('sellerAutoApproveThreshold', menuItemForm?.sellerAutoApproveThreshold ?? 0)
  }, [menuItemForm, setValue])

  const onSubmit: SubmitHandler<MenuItemFormType> = (data) => {
    itemOperation && menuSlug && menuItemSlug && dispatch(saveMenuItemAsync({
      menuSlug, menuItemSlug: (itemOperation === FormOperationType.UPDATE ? menuItemSlug : 'new'),
      operation: itemOperation, menuItem: {
        name: data.name,
        specialInstr: data.specialInstr,
        details: data.details,
        label: data.label,
        tags: data.tags,
        enableSpecialInstr: data.enableSpecialInstr,
        enableSellerAutoApprove: data.enableSellerAutoApprove,
        sellerAutoApproveThreshold: data.sellerAutoApproveThreshold,
        slug: menuItemSlug
      }, cb: () => {
        navigate(`/seller/menu/${FormOperationType.READ}/${menuSlug}`)
      }
    }))
  }

  return (<AppPage error={error} showError={showError} onErrorClose={() => dispatch(setIsError(false))}>
    <Box>
      {/* {savedMenuItem && <Navigate to={`/seller/menu/${FormOperationType.READ}`} />} */}
      {itemOperation === FormOperationType.READ ? <>
        <Card>
          <CardContent>
            <Typography variant="h5">
              {menuItemForm?.name}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="body2" gutterBottom>
                {menuItemForm?.details}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {menuItemForm?.label}
              </Typography>
            </Box>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {menuItemForm?.tags}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {menuItemForm?.specialInstr}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {menuItemForm?.enableSpecialInstr ? 'Special Instructions' : ''}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {menuItemForm?.enableSellerAutoApprove ? 'Seller Auto Approve' : ''}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {menuItemForm?.sellerAutoApproveThreshold}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" variant='contained' onClick={() => { navigate(`/seller/menu/${FormOperationType.NESTED}/${menuItemSlug}/item/${FormOperationType.UPDATE}`) }}><Edit /> Edit</Button>
            <Button size="small" color="secondary" variant='outlined' onClick={() => { navigate('/seller/menus') }}><Close /> Close</Button>
          </CardActions>
        </Card>
        <MenuItemList menuItems={menuItems?.content} />
      </>
        : <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="nameLabel">Name</InputLabel>
            <Input {...register('name', { required: true })} id="nameInput" aria-describedby="nameHelper" sx={{ pl: 2 }} />
            {errors.name ? <FormHelperText id="nameHelper" sx={{ color: red[500] }}>Name for the item. Required</FormHelperText>
              : <FormHelperText id="nameHelper">Name for the item.</FormHelperText>}
          </FormControl>
          <FormControlLabel sx={{ mb: 3 }} control={<Switch checked={advanced} onChange={() => setAdvanced(!advanced)} />} label="Advanced" />
          <Collapse in={advanced}>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <InputLabel htmlFor="specialInstrLabel">Special Instructions</InputLabel>
              <Input {...register('specialInstr')} id="specialInstrInput" aria-describedby="specialInstrHelper" sx={{ pl: 2 }} />
              <FormHelperText id="specialInstrHelper">Special instructions for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <InputLabel htmlFor="detailsLabel">Details</InputLabel>
              <Input {...register('details')} id="detailsInput" aria-describedby="detailsHelper" sx={{ pl: 2 }} />
              <FormHelperText id="detailsHelper">Details for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <InputLabel htmlFor="labelLabel">Label</InputLabel>
              <Input {...register('label')} id="labelInput" aria-describedby="labelHelper" sx={{ pl: 2 }} />
              <FormHelperText id="labelHelper">Label for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <InputLabel htmlFor="tagsLabel">Tags</InputLabel>
              <Input {...register('tags')} id="tagsInput" aria-describedby="tagsHelper" sx={{ pl: 2 }} />
              <FormHelperText id="tagsHelper">Tags for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <label>
                <input type="checkbox" {...register('enableSpecialInstr')} /> Special Instructions
              </label>
              <FormHelperText id="enableSpecialInstrHelper">Enable special instructions for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <label>
                <input type="checkbox" {...register('enableSellerAutoApprove')} /> Seller Auto Approve
              </label>
              <FormHelperText id="enableSellerAutoApproveHelper">Enable seller auto approve for the item.</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 5 }}>
              <InputLabel htmlFor="sellerAutoApproveThresholdLabel">Seller Auto Approve Threshold</InputLabel>
              <Input {...register('sellerAutoApproveThreshold')} id="sellerAutoApproveThresholdInput" aria-describedby="sellerAutoApproveThresholdHelper" sx={{ pl: 2 }} />
              <FormHelperText id="sellerAutoApproveThresholdHelper">Seller auto approve threshold for the item.</FormHelperText>
            </FormControl>
          </Collapse>
          <ButtonGroup size="small" color="secondary">
            <Button type="submit" color="primary" variant='contained' onClick={() => { console.log('saved') }}><Save /> Save</Button>
            <Button color="secondary" variant='outlined' onClick={() => { navigate(-1) }}><Close /> Cancel</Button>
          </ButtonGroup>
        </form>}
    </Box>
  </AppPage>
  )
}

export default MenuItemForm